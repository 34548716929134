<template>
    <div class="loading-dropshadow-container">
        <span class="loader"></span>
    </div>
</template>

<script setup></script>

<style lang="scss" scoped>
@import 'libs/shared/assets/scss/main.scss';

.loading-dropshadow-container {
    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: show;
    right: 0;
    z-index: 9999999999;
    .loader {
        width: 60px;
        height: 60px;
        border: 7px solid $blue-1;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
</style>
